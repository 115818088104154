// export const BASE_URL = "https://api-mss-bicc.arcplangroup.com";
export const BASE_URL = "https://api-mss-bicc.arcplangroupco.com";
export const USER_PLATFORM_SESSION_VERSION_NUMBER = "1.1";
export const ESTABLISHMENT_TOKEN = "f92dfb74-9cb9-44ad-b5ad-a946c3d1018f"; // Ahmed Omar & AbdElKarim (06/04/2022)

export const DESKTOP_STATUS = false;
export const DESKTOP_LINK = "#";

export const API_DEFULT_TIMEOUT = 60000;
export const API_LONG_TIMEOUT = 86400000;

export const PAGE_SIZE = 50;
export const IMPOSSIBLE_TOKEN = 0;
export const MAX_ROWS_TEXTAREA = 100;
export const DEFAULT_DIGITS = 2;
export const DEFAULT_DATE = "2000/01/01";

export const STATUS = {
  SUCCESS: 200, // success operation
  INVALID_TOKEN: 498, //invalid token
  CATCH: 500, // catch
  NO_CONTENT: 204, // no data
  ERROR: 406, // validation
  VERSION: 306, // error version
  INVALID_LOGIN_CODE: 499, // error login code
  NO_INTERNET: 420, // no internet
  NO_RESPONSE: 421, // no response
};

//#region DefaultPages & Languages
export const DEFAULT_PAGES = {
  notLoggedIn: "Home",
  loggedIn: "Students",
};

// ##duplicated in functions file
export const LANGUAGES = {
  list: ["ar-EG", "en-US", "und"],
  default: "ar-EG",
  arEG: "ar-EG",
  enUS: "en-US",
  und: "und",
  defaultCountryCode: "+20",
  defaultCountryCodeName: "EG",
};
//#endregion DefaultPages & Languages

//#region layoutDisplaies
export const VIEW_TYPES = {
  default: "table",
  table: "table",
  cards: "cards",
  list: ["table", "cards"],
};
//#endregion layoutDisplaies

//#region News
export const NEWS_MEDIA_TYPES = {
  image: "MTT-1",
  video: "MTT-2",
};
//#endregion News

//#region Reports
export const FILTER_REPORT_TYPES = {
  Default: "Group",
  Group: "Group",
  Place: "Place",
  Employee: "Employee",
  Student: "Student",
  Attendance: "Attendance",
};
//#endregion Reports

export const VALIDATION = {
  MIN_LENGTH_NAME: 3,
  MAX_LENGTH_NAME: 400,
  MIN_LENGTH_TEXT: 0,
  MAX_LENGTH_TEXT: 1000000,
};

export const KEY_CODE = {
  backspace: 8,
  backspaceMob: 229,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};
