<template>
  <div id="app" ref="viewBox">
    <component id="app-container" :is="resolveLayout">
      <router-view></router-view>
    </component>
    <AlertsList />
  </div>
</template>

<script>
import "animate.css";
import { mapGetters } from "vuex";
import { getLanguage } from "./utils/functions";
import { LANGUAGES } from "./utils/constants";
import AuthLayout from "./layouts/AuthLayout.vue";
import DashboardLayout from "./layouts/DashboardLayout.vue";
import AlertsList from "./components/general/AlertsList.vue";
import generalMixin from "./utils/generalMixin";

export default {
  name: "App",
  mixins: [generalMixin],
  components: {
    AuthLayout,
    DashboardLayout,
    AlertsList,
  },
  data() {
    return {
      lastScrollTop: 0,
    };
  },
  methods: {
    setProjectNameTitle() {
      document.title = this.$t("projectName");
    },
    setRootStyle() {
      let language = getLanguage();
      let root = document.documentElement;

      if (language == LANGUAGES.arEG) root.className = "v-application--is-rtl";
      else root.className = "v-application--is-ltr";
    },
  },
  computed: {
    ...mapGetters(["invalidLoginCodeStatus", "invalidLoginCodeMessage"]),

    resolveLayout: function () {
      if (this.$route.meta.layout === "auth") return "auth-layout";
      else return "dashboard-layout";
    },
  },
  watch: {
    invalidLoginCodeStatus: function (invalid) {
      if (invalid) {
        this.$router.push({ name: "InvalidLoginClode" }).catch(() => {});
      } else {
        this.$router.push({ name: this.userHomePage }).catch(() => {});
      }
    },
  },
  async created() {
    this.setProjectNameTitle();
    this.setRootStyle();
  },
};
</script>
