import store from "./../store";

//#region Users
export const hasUsers = () => {
  return (
    hasAdminsEgypt() ||
    hasMasterAdmin() ||
    hasEmployee() ||
    hasStudent() ||
    hasParent() ||
    hasUserSettings()
  );
};

//#region AdminsEgypt
export const hasAdminsEgypt = () => {
  return store.getters.usersPrivilegeData.AdminsEgypt.view;
};
export const hasAdminsEgyptViewActive = () => {
  return store.getters.usersPrivilegeData.AdminsEgypt.viewActive;
};
export const hasAdminsEgyptViewArchive = () => {
  return store.getters.usersPrivilegeData.AdminsEgypt.viewArchived;
};
export const hasAdminsEgyptViewBlocked = () => {
  return store.getters.usersPrivilegeData.AdminsEgypt.viewBlocked;
};

export const hasAdminsEgyptAdd = () => {
  return store.getters.usersPrivilegeData.AdminsEgypt.itemAdd;
};
export const hasAdminsEgyptEdit = () => {
  return store.getters.usersPrivilegeData.AdminsEgypt.itemUpdate;
};

export const hasAdminsEgyptDeleteImage = () => {
  return store.getters.usersPrivilegeData.AdminsEgypt.itemDeleteImage;
};
export const hasAdminsEgyptFinaleDelete = () => {
  return store.getters.usersPrivilegeData.AdminsEgypt.itemFinaleDelete;
};

export const hasAdminsEgyptChangeActivationType = () => {
  return store.getters.usersPrivilegeData.AdminsEgypt.changeActivationType;
};
export const hasAdminsEgyptActive = () => {
  return store.getters.usersPrivilegeData.AdminsEgypt.itemActive;
};
export const hasAdminsEgyptArchive = () => {
  return store.getters.usersPrivilegeData.AdminsEgypt.itemArchived;
};
export const hasAdminsEgyptBlocked = () => {
  return store.getters.usersPrivilegeData.AdminsEgypt.itemBlocked;
};
//#endregion AdminsEgypt

//#region MasterAdmin
export const hasMasterAdmin = () => {
  return store.getters.usersPrivilegeData.MasterAdmin.view;
};
export const hasMasterAdminViewActive = () => {
  return store.getters.usersPrivilegeData.MasterAdmin.viewActive;
};
export const hasMasterAdminViewArchive = () => {
  return store.getters.usersPrivilegeData.MasterAdmin.viewArchived;
};
export const hasMasterAdminViewBlocked = () => {
  return store.getters.usersPrivilegeData.MasterAdmin.viewBlocked;
};

export const hasMasterAdminAdd = () => {
  return store.getters.usersPrivilegeData.MasterAdmin.itemAdd;
};
export const hasMasterAdminEdit = () => {
  return store.getters.usersPrivilegeData.MasterAdmin.itemUpdate;
};

export const hasMasterAdminDeleteImage = () => {
  return store.getters.usersPrivilegeData.MasterAdmin.itemDeleteImage;
};
export const hasMasterAdminFinaleDelete = () => {
  return store.getters.usersPrivilegeData.MasterAdmin.itemFinaleDelete;
};

export const hasMasterAdminChangeActivationType = () => {
  return store.getters.usersPrivilegeData.MasterAdmin.changeActivationType;
};
export const hasMasterAdminActive = () => {
  return store.getters.usersPrivilegeData.MasterAdmin.itemActive;
};
export const hasMasterAdminArchive = () => {
  return store.getters.usersPrivilegeData.MasterAdmin.itemArchived;
};
export const hasMasterAdminBlocked = () => {
  return store.getters.usersPrivilegeData.MasterAdmin.itemBlocked;
};
//#endregion MasterAdmin

//#region Employee
export const hasEmployee = () => {
  return store.getters.usersPrivilegeData.Employee.view;
};
export const hasEmployeeViewActive = () => {
  return store.getters.usersPrivilegeData.Employee.viewActive;
};
export const hasEmployeeViewArchive = () => {
  return store.getters.usersPrivilegeData.Employee.viewArchived;
};
export const hasEmployeeViewBlocked = () => {
  return store.getters.usersPrivilegeData.Employee.viewBlocked;
};

export const hasEmployeeAdd = () => {
  return store.getters.usersPrivilegeData.Employee.itemAdd;
};
export const hasEmployeeEdit = () => {
  return store.getters.usersPrivilegeData.Employee.itemUpdate;
};

export const hasEmployeeDeleteImage = () => {
  return store.getters.usersPrivilegeData.Employee.itemDeleteImage;
};
export const hasEmployeeFinaleDelete = () => {
  return store.getters.usersPrivilegeData.Employee.itemFinaleDelete;
};

export const hasEmployeeChangeActivationType = () => {
  return store.getters.usersPrivilegeData.Employee.changeActivationType;
};
export const hasEmployeeActive = () => {
  return store.getters.usersPrivilegeData.Employee.itemActive;
};
export const hasEmployeeArchive = () => {
  return store.getters.usersPrivilegeData.Employee.itemArchived;
};
export const hasEmployeeBlocked = () => {
  return store.getters.usersPrivilegeData.Employee.itemBlocked;
};
//#endregion Employee

//#region Student
export const hasStudent = () => {
  return store.getters.usersPrivilegeData.Student.view;
};
export const hasStudentsIdReport = () => {
  return store.getters.usersPrivilegeData.Student.viewReport;
};
export const hasStudentsWalletReport = () => {
  return store.getters.usersPrivilegeData.Student.viewReport;
};
export const hasStudentViewActive = () => {
  return store.getters.usersPrivilegeData.Student.viewActive;
};
export const hasStudentViewArchive = () => {
  return store.getters.usersPrivilegeData.Student.viewArchived;
};
export const hasStudentViewBlocked = () => {
  return store.getters.usersPrivilegeData.Student.viewBlocked;
};

export const hasStudentAdd = () => {
  return store.getters.usersPrivilegeData.Student.itemAdd;
};
export const hasStudentEdit = () => {
  return store.getters.usersPrivilegeData.Student.itemUpdate;
};

export const hasStudentDeleteImage = () => {
  return store.getters.usersPrivilegeData.Student.itemDeleteImage;
};
export const hasStudentFinaleDelete = () => {
  return store.getters.usersPrivilegeData.Student.itemFinaleDelete;
};

export const hasStudentChangeActivationType = () => {
  return store.getters.usersPrivilegeData.Student.changeActivationType;
};
export const hasStudentActive = () => {
  return store.getters.usersPrivilegeData.Student.itemActive;
};
export const hasStudentArchive = () => {
  return store.getters.usersPrivilegeData.Student.itemArchived;
};
export const hasStudentBlocked = () => {
  return store.getters.usersPrivilegeData.Student.itemBlocked;
};
//#endregion Student

//#region Parent
export const hasParent = () => {
  return store.getters.usersPrivilegeData.Parent.view;
};
export const hasParentViewActive = () => {
  return store.getters.usersPrivilegeData.Parent.viewActive;
};
export const hasParentViewArchive = () => {
  return store.getters.usersPrivilegeData.Parent.viewArchived;
};
export const hasParentViewBlocked = () => {
  return store.getters.usersPrivilegeData.Parent.viewBlocked;
};

export const hasParentAdd = () => {
  return store.getters.usersPrivilegeData.Parent.itemAdd;
};
export const hasParentEdit = () => {
  return store.getters.usersPrivilegeData.Parent.itemUpdate;
};

export const hasParentDeleteImage = () => {
  return store.getters.usersPrivilegeData.Parent.itemDeleteImage;
};
export const hasParentFinaleDelete = () => {
  return store.getters.usersPrivilegeData.Parent.itemFinaleDelete;
};

export const hasParentChangeActivationType = () => {
  return store.getters.usersPrivilegeData.Parent.changeActivationType;
};
export const hasParentActive = () => {
  return store.getters.usersPrivilegeData.Parent.itemActive;
};
export const hasParentArchive = () => {
  return store.getters.usersPrivilegeData.Parent.itemArchived;
};
export const hasParentBlocked = () => {
  return store.getters.usersPrivilegeData.Parent.itemBlocked;
};
//#endregion Parent

//#endregion Users

//#region Settings

//#region SettingsOthers
export const hasSettingsOthers = () => {
  return hasCategory() || hasProduct() || hasEducationalStage();
};

//#region Categories
export const hasCategory = () => {
  try {
    return store.getters.usersPrivilegeData.Category.view;
  } catch (error) {
    return false;
  }
};
export const hasCategoryReport = () => {
  try {
    return store.getters.usersPrivilegeData.Category.viewReport;
  } catch (error) {
    return false;
  }
};
export const hasCategoryViewActive = () => {
  return store.getters.usersPrivilegeData.Category.viewActive;
};

export const hasCategoryViewBlocked = () => {
  return store.getters.usersPrivilegeData.Category.viewBlocked;
};

export const hasCategoryAdd = () => {
  return store.getters.usersPrivilegeData.Category.itemAdd;
};
export const hasCategoryEdit = () => {
  return store.getters.usersPrivilegeData.Category.itemUpdate;
};

export const hasCategoryDeleteImage = () => {
  return store.getters.usersPrivilegeData.Category.itemDeleteImage;
};
export const hasCategoryFinaleDelete = () => {
  return store.getters.usersPrivilegeData.Category.itemFinaleDelete;
};

export const hasCategoryChangeActivationType = () => {
  return store.getters.usersPrivilegeData.Category.changeActivationType;
};
export const hasCategoryActive = () => {
  return store.getters.usersPrivilegeData.Category.itemActive;
};
export const hasCategoryArchive = () => {
  return store.getters.usersPrivilegeData.Category.itemArchived;
};
export const hasCategoryBlocked = () => {
  return store.getters.usersPrivilegeData.Category.itemBlocked;
};
//#endregion Categories

//#region Products
export const hasProduct = () => {
  try {
    return store.getters.usersPrivilegeData.Product.view;
  } catch (error) {
    return false;
  }
};
export const hasProductReport = () => {
  try {
    return store.getters.usersPrivilegeData.Product.viewReport;
  } catch (error) {
    return false;
  }
};
export const hasProductViewActive = () => {
  return store.getters.usersPrivilegeData.Product.viewActive;
};

export const hasProductViewBlocked = () => {
  return store.getters.usersPrivilegeData.Product.viewBlocked;
};

export const hasProductAdd = () => {
  return store.getters.usersPrivilegeData.Product.itemAdd;
};
export const hasProductEdit = () => {
  return store.getters.usersPrivilegeData.Product.itemUpdate;
};

export const hasProductDeleteImage = () => {
  return store.getters.usersPrivilegeData.Product.itemDeleteImage;
};
export const hasProductFinaleDelete = () => {
  return store.getters.usersPrivilegeData.Product.itemFinaleDelete;
};

export const hasProductChangeActivationType = () => {
  return store.getters.usersPrivilegeData.Product.changeActivationType;
};
export const hasProductActive = () => {
  return store.getters.usersPrivilegeData.Product.itemActive;
};

export const hasProductBlocked = () => {
  return store.getters.usersPrivilegeData.Product.itemBlocked;
};
//#endregion Products

//#region UserWalletTransactions
export const hasUserWalletTransaction = () => {
  try {
    return store.getters.usersPrivilegeData.UserWalletTransaction
      .userWalletTransactionView;
  } catch (error) {
    return false;
  }
};
export const hasUserWalletTransactionReport = () => {
  return store.getters.usersPrivilegeData.UserWalletTransaction.viewReport;
};

export const hasUserWalletTransactionReceiptReport = () => {
  return store.getters.usersPrivilegeData.UserWalletTransaction.viewReport;
};

export const hasUserWalletTransactionAdd = () => {
  return (
    hasUserWalletTransactionCharging() || hasUserWalletTransactionWithdrawing()
  );
};

export const hasUserWalletTransactionCharging = () => {
  return store.getters.usersPrivilegeData.UserWalletTransaction
    .userWalletTransactionCharging;
};
export const hasUserWalletTransactionWithdrawing = () => {
  return store.getters.usersPrivilegeData.UserWalletTransaction
    .userWalletTransactionWithdrawing;
};

//#endregion UserWalletTransactions

//#region EducationalStages
export const hasEducationalStage = () => {
  try {
    return store.getters.usersPrivilegeData.EducationalStage.view;
  } catch (error) {
    return false;
  }
};
export const hasEducationalStageReport = () => {
  try {
    return store.getters.usersPrivilegeData.EducationalStage.viewReport;
  } catch (error) {
    return false;
  }
};
export const hasEducationalStageViewActive = () => {
  return store.getters.usersPrivilegeData.EducationalStage.viewActive;
};

export const hasEducationalStageViewBlocked = () => {
  return store.getters.usersPrivilegeData.EducationalStage.viewBlocked;
};

export const hasEducationalStageAdd = () => {
  return store.getters.usersPrivilegeData.EducationalStage.itemAdd;
};
export const hasEducationalStageEdit = () => {
  return store.getters.usersPrivilegeData.EducationalStage.itemUpdate;
};

export const hasEducationalStageDeleteImage = () => {
  return store.getters.usersPrivilegeData.EducationalStage.itemDeleteImage;
};
export const hasEducationalStageFinaleDelete = () => {
  return store.getters.usersPrivilegeData.EducationalStage.itemFinaleDelete;
};

export const hasEducationalStageChangeActivationType = () => {
  return store.getters.usersPrivilegeData.EducationalStage.changeActivationType;
};
export const hasEducationalStageActive = () => {
  return store.getters.usersPrivilegeData.EducationalStage.itemActive;
};

export const hasEducationalStageBlocked = () => {
  return store.getters.usersPrivilegeData.EducationalStage.itemBlocked;
};
//#endregion EducationalStages

//#region UserEducationalStages
export const hasUserEducationalStage = () => {
  try {
    return store.getters.usersPrivilegeData.UserEducationalStage.view;
  } catch (error) {
    return false;
  }
};
export const hasUserEducationalStageFinaleDelete = () => {
  return store.getters.usersPrivilegeData.UserEducationalStage.itemFinaleDelete;
};
//#endregion UserEducationalStages

//#endregion SettingsOthers

//#region UserSettings
export const hasUserSettings = () => {
  return (
    hasUserValidSettingsTabsMasterAdmin() ||
    hasUserValidSettingsTabsEmployee() ||
    hasUserValidSettingsTabsStudent() ||
    hasUserValidSettingsTabsParent()
  );
};
//#region UserValidSettingsTabs

// MasterAdmin
export const hasUserValidSettingsTabsMasterAdmin = () => {
  return (
    hasMasterAdminEstablishmentsRole() ||
    hasMasterAdminValidSetting() ||
    hasMasterAdminLoginCode()
  );
};
// Employee
export const hasUserValidSettingsTabsEmployee = () => {
  return (
    hasEmployeeEstablishmentsRole() ||
    hasEmployeeValidSetting() ||
    hasEmployeeLoginCode()
  );
};
// Student
export const hasUserValidSettingsTabsStudent = () => {
  return (
    hasStudentEstablishmentsRole() ||
    hasStudentValidSetting() ||
    hasStudentLoginCode()
  );
};
// Parent
export const hasUserValidSettingsTabsParent = () => {
  return (
    hasParentEstablishmentsRole() ||
    hasParentValidSetting() ||
    hasParentLoginCode()
  );
};

//#endregion UserValidSettingsTabs

//#region UserValidSettings
export const hasUserValidSetting = () => {
  return (
    hasAdminsEgyptValidSetting() ||
    hasMasterAdminValidSetting() ||
    hasEmployeeValidSetting() ||
    hasStudentValidSetting() ||
    hasParentValidSetting()
  );
};

//#region Student
export const hasStudentValidSetting = () => {
  return store.getters.usersPrivilegeData.StudentValidSetting.view;
};
export const hasStudentValidSettingViewActive = () => {
  return store.getters.usersPrivilegeData.StudentValidSetting.viewActive;
};
export const hasStudentValidSettingViewArchive = () => {
  return store.getters.usersPrivilegeData.StudentValidSetting.viewArchived;
};
export const hasStudentValidSettingViewBlocked = () => {
  return store.getters.usersPrivilegeData.StudentValidSetting.viewBlocked;
};

export const hasStudentValidSettingAdd = () => {
  return store.getters.usersPrivilegeData.StudentValidSetting.itemAdd;
};
export const hasStudentValidSettingEdit = () => {
  return store.getters.usersPrivilegeData.StudentValidSetting.itemUpdate;
};

export const hasStudentValidSettingDeleteImage = () => {
  return store.getters.usersPrivilegeData.StudentValidSetting.itemDeleteImage;
};
export const hasStudentValidSettingFinaleDelete = () => {
  return store.getters.usersPrivilegeData.StudentValidSetting.itemFinaleDelete;
};

export const hasStudentValidSettingChangeActivationType = () => {
  return store.getters.usersPrivilegeData.StudentValidSetting
    .changeActivationType;
};
export const hasStudentValidSettingActive = () => {
  return store.getters.usersPrivilegeData.StudentValidSetting.itemActive;
};
export const hasStudentValidSettingArchive = () => {
  return store.getters.usersPrivilegeData.StudentValidSetting.itemArchived;
};
export const hasStudentValidSettingBlocked = () => {
  return store.getters.usersPrivilegeData.StudentValidSetting.itemBlocked;
};
//#endregion Student

//#region Parent
export const hasParentValidSetting = () => {
  return store.getters.usersPrivilegeData.ParentValidSetting.view;
};
export const hasParentValidSettingViewActive = () => {
  return store.getters.usersPrivilegeData.ParentValidSetting.viewActive;
};
export const hasParentValidSettingViewArchive = () => {
  return store.getters.usersPrivilegeData.ParentValidSetting.viewArchived;
};
export const hasParentValidSettingViewBlocked = () => {
  return store.getters.usersPrivilegeData.ParentValidSetting.viewBlocked;
};

export const hasParentValidSettingAdd = () => {
  return store.getters.usersPrivilegeData.ParentValidSetting.itemAdd;
};
export const hasParentValidSettingEdit = () => {
  return store.getters.usersPrivilegeData.ParentValidSetting.itemUpdate;
};

export const hasParentValidSettingDeleteImage = () => {
  return store.getters.usersPrivilegeData.ParentValidSetting.itemDeleteImage;
};
export const hasParentValidSettingFinaleDelete = () => {
  return store.getters.usersPrivilegeData.ParentValidSetting.itemFinaleDelete;
};

export const hasParentValidSettingChangeActivationType = () => {
  return store.getters.usersPrivilegeData.ParentValidSetting
    .changeActivationType;
};
export const hasParentValidSettingActive = () => {
  return store.getters.usersPrivilegeData.ParentValidSetting.itemActive;
};
export const hasParentValidSettingArchive = () => {
  return store.getters.usersPrivilegeData.ParentValidSetting.itemArchived;
};
export const hasParentValidSettingBlocked = () => {
  return store.getters.usersPrivilegeData.ParentValidSetting.itemBlocked;
};
//#endregion Parent

//#region Employee
export const hasEmployeeValidSetting = () => {
  return store.getters.usersPrivilegeData.EmployeeValidSetting.view;
};
export const hasEmployeeValidSettingViewActive = () => {
  return store.getters.usersPrivilegeData.EmployeeValidSetting.viewActive;
};
export const hasEmployeeValidSettingViewArchive = () => {
  return store.getters.usersPrivilegeData.EmployeeValidSetting.viewArchived;
};
export const hasEmployeeValidSettingViewBlocked = () => {
  return store.getters.usersPrivilegeData.EmployeeValidSetting.viewBlocked;
};

export const hasEmployeeValidSettingAdd = () => {
  return store.getters.usersPrivilegeData.EmployeeValidSetting.itemAdd;
};
export const hasEmployeeValidSettingEdit = () => {
  return store.getters.usersPrivilegeData.EmployeeValidSetting.itemUpdate;
};

export const hasEmployeeValidSettingDeleteImage = () => {
  return store.getters.usersPrivilegeData.EmployeeValidSetting.itemDeleteImage;
};
export const hasEmployeeValidSettingFinaleDelete = () => {
  return store.getters.usersPrivilegeData.EmployeeValidSetting.itemFinaleDelete;
};

export const hasEmployeeValidSettingChangeActivationType = () => {
  return store.getters.usersPrivilegeData.EmployeeValidSetting
    .changeActivationType;
};
export const hasEmployeeValidSettingActive = () => {
  return store.getters.usersPrivilegeData.EmployeeValidSetting.itemActive;
};
export const hasEmployeeValidSettingArchive = () => {
  return store.getters.usersPrivilegeData.EmployeeValidSetting.itemArchived;
};
export const hasEmployeeValidSettingBlocked = () => {
  return store.getters.usersPrivilegeData.EmployeeValidSetting.itemBlocked;
};
//#endregion Employee

//#region MasterAdmin
export const hasMasterAdminValidSetting = () => {
  return store.getters.usersPrivilegeData.MasterAdminValidSetting.view;
};
export const hasMasterAdminValidSettingViewActive = () => {
  return store.getters.usersPrivilegeData.MasterAdminValidSetting.viewActive;
};
export const hasMasterAdminValidSettingViewArchive = () => {
  return store.getters.usersPrivilegeData.MasterAdminValidSetting.viewArchived;
};
export const hasMasterAdminValidSettingViewBlocked = () => {
  return store.getters.usersPrivilegeData.MasterAdminValidSetting.viewBlocked;
};

export const hasMasterAdminValidSettingAdd = () => {
  return store.getters.usersPrivilegeData.MasterAdminValidSetting.itemAdd;
};
export const hasMasterAdminValidSettingEdit = () => {
  return store.getters.usersPrivilegeData.MasterAdminValidSetting.itemUpdate;
};

export const hasMasterAdminValidSettingDeleteImage = () => {
  return store.getters.usersPrivilegeData.MasterAdminValidSetting
    .itemDeleteImage;
};
export const hasMasterAdminValidSettingFinaleDelete = () => {
  return store.getters.usersPrivilegeData.MasterAdminValidSetting
    .itemFinaleDelete;
};

export const hasMasterAdminValidSettingChangeActivationType = () => {
  return store.getters.usersPrivilegeData.MasterAdminValidSetting
    .changeActivationType;
};
export const hasMasterAdminValidSettingActive = () => {
  return store.getters.usersPrivilegeData.MasterAdminValidSetting.itemActive;
};
export const hasMasterAdminValidSettingArchive = () => {
  return store.getters.usersPrivilegeData.MasterAdminValidSetting.itemArchived;
};
export const hasMasterAdminValidSettingBlocked = () => {
  return store.getters.usersPrivilegeData.MasterAdminValidSetting.itemBlocked;
};
//#endregion MasterAdmin

//#region AdminsEgypt
export const hasAdminsEgyptValidSetting = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptValidSetting.view;
};
export const hasAdminsEgyptValidSettingViewActive = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptValidSetting.viewActive;
};
export const hasAdminsEgyptValidSettingViewArchive = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptValidSetting.viewArchived;
};
export const hasAdminsEgyptValidSettingViewBlocked = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptValidSetting.viewBlocked;
};

export const hasAdminsEgyptValidSettingAdd = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptValidSetting.itemAdd;
};
export const hasAdminsEgyptValidSettingEdit = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptValidSetting.itemUpdate;
};

export const hasAdminsEgyptValidSettingDeleteImage = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptValidSetting
    .itemDeleteImage;
};
export const hasAdminsEgyptValidSettingFinaleDelete = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptValidSetting
    .itemFinaleDelete;
};

export const hasAdminsEgyptValidSettingChangeActivationType = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptValidSetting
    .changeActivationType;
};
export const hasAdminsEgyptValidSettingActive = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptValidSetting.itemActive;
};
export const hasAdminsEgyptValidSettingArchive = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptValidSetting.itemArchived;
};
export const hasAdminsEgyptValidSettingBlocked = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptValidSetting.itemBlocked;
};
//#endregion AdminsEgypt

//#endregion UserValidSettings

//#region EstablishmentsRoles
export const hasEstablishmentsRole = () => {
  return (
    hasMasterAdminEstablishmentsRole() ||
    hasEmployeeEstablishmentsRole() ||
    hasStudentEstablishmentsRole() ||
    hasParentEstablishmentsRole()
  );
};
export const hasEstablishmentRoleEdit = (modelName) => {
  let status = false;
  switch (modelName) {
    case "masterAdminEstablishmentsRole":
      status = hasMasterAdminEstablishmentsRoleEdit();
      break;
    case "employeeEstablishmentsRole":
      status = hasEmployeeEstablishmentsRoleEdit();
      break;
    case "studentEstablishmentsRole":
      status = hasStudentEstablishmentsRoleEdit();
      break;
    case "parentEstablishmentsRole":
      status = hasParentEstablishmentsRoleEdit();
      break;
  }
  return status;
};

//#region Student
export const hasStudentEstablishmentsRole = () => {
  try {
    return store.getters.usersPrivilegeData.StudentEstablishmentsRole.view;
  } catch (error) {
    return false;
  }
};
export const hasStudentEstablishmentsRoleViewActive = () => {
  return store.getters.usersPrivilegeData.StudentEstablishmentsRole.viewActive;
};
export const hasStudentEstablishmentsRoleViewArchive = () => {
  return store.getters.usersPrivilegeData.StudentEstablishmentsRole
    .viewArchived;
};
export const hasStudentEstablishmentsRoleViewBlocked = () => {
  return store.getters.usersPrivilegeData.StudentEstablishmentsRole.viewBlocked;
};

export const hasStudentEstablishmentsRoleAdd = () => {
  return store.getters.usersPrivilegeData.StudentEstablishmentsRole.itemAdd;
};
export const hasStudentEstablishmentsRoleEdit = () => {
  return store.getters.usersPrivilegeData.StudentEstablishmentsRole.itemUpdate;
};

export const hasStudentEstablishmentsRoleDeleteImage = () => {
  return store.getters.usersPrivilegeData.StudentEstablishmentsRole
    .itemDeleteImage;
};
export const hasStudentEstablishmentsRoleFinaleDelete = () => {
  return store.getters.usersPrivilegeData.StudentEstablishmentsRole
    .itemFinaleDelete;
};

export const hasStudentEstablishmentsRoleChangeActivationType = () => {
  return store.getters.usersPrivilegeData.StudentEstablishmentsRole
    .changeActivationType;
};
export const hasStudentEstablishmentsRoleActive = () => {
  return store.getters.usersPrivilegeData.StudentEstablishmentsRole.itemActive;
};
export const hasStudentEstablishmentsRoleArchive = () => {
  return store.getters.usersPrivilegeData.StudentEstablishmentsRole
    .itemArchived;
};
export const hasStudentEstablishmentsRoleBlocked = () => {
  return store.getters.usersPrivilegeData.StudentEstablishmentsRole.itemBlocked;
};
//#endregion Student

//#region Parent
export const hasParentEstablishmentsRole = () => {
  try {
    return store.getters.usersPrivilegeData.ParentEstablishmentsRole.view;
  } catch (error) {
    return false;
  }
};
export const hasParentEstablishmentsRoleViewActive = () => {
  return store.getters.usersPrivilegeData.ParentEstablishmentsRole.viewActive;
};
export const hasParentEstablishmentsRoleViewArchive = () => {
  return store.getters.usersPrivilegeData.ParentEstablishmentsRole.viewArchived;
};
export const hasParentEstablishmentsRoleViewBlocked = () => {
  return store.getters.usersPrivilegeData.ParentEstablishmentsRole.viewBlocked;
};

export const hasParentEstablishmentsRoleAdd = () => {
  return store.getters.usersPrivilegeData.ParentEstablishmentsRole.itemAdd;
};
export const hasParentEstablishmentsRoleEdit = () => {
  return store.getters.usersPrivilegeData.ParentEstablishmentsRole.itemUpdate;
};

export const hasParentEstablishmentsRoleDeleteImage = () => {
  return store.getters.usersPrivilegeData.ParentEstablishmentsRole
    .itemDeleteImage;
};
export const hasParentEstablishmentsRoleFinaleDelete = () => {
  return store.getters.usersPrivilegeData.ParentEstablishmentsRole
    .itemFinaleDelete;
};

export const hasParentEstablishmentsRoleChangeActivationType = () => {
  return store.getters.usersPrivilegeData.ParentEstablishmentsRole
    .changeActivationType;
};
export const hasParentEstablishmentsRoleActive = () => {
  return store.getters.usersPrivilegeData.ParentEstablishmentsRole.itemActive;
};
export const hasParentEstablishmentsRoleArchive = () => {
  return store.getters.usersPrivilegeData.ParentEstablishmentsRole.itemArchived;
};
export const hasParentEstablishmentsRoleBlocked = () => {
  return store.getters.usersPrivilegeData.ParentEstablishmentsRole.itemBlocked;
};
//#endregion Parent

//#region Employee
export const hasEmployeeEstablishmentsRole = () => {
  try {
    return store.getters.usersPrivilegeData.EmployeeEstablishmentsRole.view;
  } catch (error) {
    return false;
  }
};
export const hasEmployeeEstablishmentsRoleViewActive = () => {
  return store.getters.usersPrivilegeData.EmployeeEstablishmentsRole.viewActive;
};
export const hasEmployeeEstablishmentsRoleViewArchive = () => {
  return store.getters.usersPrivilegeData.EmployeeEstablishmentsRole
    .viewArchived;
};
export const hasEmployeeEstablishmentsRoleViewBlocked = () => {
  return store.getters.usersPrivilegeData.EmployeeEstablishmentsRole
    .viewBlocked;
};

export const hasEmployeeEstablishmentsRoleAdd = () => {
  return store.getters.usersPrivilegeData.EmployeeEstablishmentsRole.itemAdd;
};
export const hasEmployeeEstablishmentsRoleEdit = () => {
  return store.getters.usersPrivilegeData.EmployeeEstablishmentsRole.itemUpdate;
};

export const hasEmployeeEstablishmentsRoleDeleteImage = () => {
  return store.getters.usersPrivilegeData.EmployeeEstablishmentsRole
    .itemDeleteImage;
};
export const hasEmployeeEstablishmentsRoleFinaleDelete = () => {
  return store.getters.usersPrivilegeData.EmployeeEstablishmentsRole
    .itemFinaleDelete;
};

export const hasEmployeeEstablishmentsRoleChangeActivationType = () => {
  return store.getters.usersPrivilegeData.EmployeeEstablishmentsRole
    .changeActivationType;
};
export const hasEmployeeEstablishmentsRoleActive = () => {
  return store.getters.usersPrivilegeData.EmployeeEstablishmentsRole.itemActive;
};
export const hasEmployeeEstablishmentsRoleArchive = () => {
  return store.getters.usersPrivilegeData.EmployeeEstablishmentsRole
    .itemArchived;
};
export const hasEmployeeEstablishmentsRoleBlocked = () => {
  return store.getters.usersPrivilegeData.EmployeeEstablishmentsRole
    .itemBlocked;
};
//#endregion Employee

//#region MasterAdmin
export const hasMasterAdminEstablishmentsRole = () => {
  try {
    return store.getters.usersPrivilegeData.MasterAdminEstablishmentsRole.view;
  } catch (error) {
    return false;
  }
};
export const hasMasterAdminEstablishmentsRoleViewActive = () => {
  return store.getters.usersPrivilegeData.MasterAdminEstablishmentsRole
    .viewActive;
};
export const hasMasterAdminEstablishmentsRoleViewArchive = () => {
  return store.getters.usersPrivilegeData.MasterAdminEstablishmentsRole
    .viewArchived;
};
export const hasMasterAdminEstablishmentsRoleViewBlocked = () => {
  return store.getters.usersPrivilegeData.MasterAdminEstablishmentsRole
    .viewBlocked;
};

export const hasMasterAdminEstablishmentsRoleAdd = () => {
  return store.getters.usersPrivilegeData.MasterAdminEstablishmentsRole.itemAdd;
};
export const hasMasterAdminEstablishmentsRoleEdit = () => {
  return store.getters.usersPrivilegeData.MasterAdminEstablishmentsRole
    .itemUpdate;
};

export const hasMasterAdminEstablishmentsRoleDeleteImage = () => {
  return store.getters.usersPrivilegeData.MasterAdminEstablishmentsRole
    .itemDeleteImage;
};
export const hasMasterAdminEstablishmentsRoleFinaleDelete = () => {
  return store.getters.usersPrivilegeData.MasterAdminEstablishmentsRole
    .itemFinaleDelete;
};

export const hasMasterAdminEstablishmentsRoleChangeActivationType = () => {
  return store.getters.usersPrivilegeData.MasterAdminEstablishmentsRole
    .changeActivationType;
};
export const hasMasterAdminEstablishmentsRoleActive = () => {
  return store.getters.usersPrivilegeData.MasterAdminEstablishmentsRole
    .itemActive;
};
export const hasMasterAdminEstablishmentsRoleArchive = () => {
  return store.getters.usersPrivilegeData.MasterAdminEstablishmentsRole
    .itemArchived;
};
export const hasMasterAdminEstablishmentsRoleBlocked = () => {
  return store.getters.usersPrivilegeData.MasterAdminEstablishmentsRole
    .itemBlocked;
};
//#endregion MasterAdmin

//#region AdminsEgypt
export const hasAdminsEgyptEstablishmentsRole = () => {
  try {
    return store.getters.usersPrivilegeData.AdminsEgyptEstablishmentsRole.view;
  } catch (error) {
    return false;
  }
};
export const hasAdminsEgyptEstablishmentsRoleViewActive = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptEstablishmentsRole
    .viewActive;
};
export const hasAdminsEgyptEstablishmentsRoleViewArchive = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptEstablishmentsRole
    .viewArchived;
};
export const hasAdminsEgyptEstablishmentsRoleViewBlocked = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptEstablishmentsRole
    .viewBlocked;
};

export const hasAdminsEgyptEstablishmentsRoleAdd = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptEstablishmentsRole.itemAdd;
};
export const hasAdminsEgyptEstablishmentsRoleEdit = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptEstablishmentsRole
    .itemUpdate;
};

export const hasAdminsEgyptEstablishmentsRoleDeleteImage = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptEstablishmentsRole
    .itemDeleteImage;
};
export const hasAdminsEgyptEstablishmentsRoleFinaleDelete = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptEstablishmentsRole
    .itemFinaleDelete;
};

export const hasAdminsEgyptEstablishmentsRoleChangeActivationType = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptEstablishmentsRole
    .changeActivationType;
};
export const hasAdminsEgyptEstablishmentsRoleActive = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptEstablishmentsRole
    .itemActive;
};
export const hasAdminsEgyptEstablishmentsRoleArchive = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptEstablishmentsRole
    .itemArchived;
};
export const hasAdminsEgyptEstablishmentsRoleBlocked = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptEstablishmentsRole
    .itemBlocked;
};
//#endregion AdminsEgypt

//#endregion EstablishmentsRoles

//#region UserLoginCodes
export const hasUserLoginCode = () => {
  return (
    hasAdminsEgyptLoginCode() ||
    hasMasterAdminLoginCode() ||
    hasEmployeeLoginCode() ||
    hasStudentLoginCode() ||
    hasParentLoginCode()
  );
};

//#region Student
export const hasStudentLoginCode = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.view;
};
export const hasStudentLoginCodeViewActive = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.viewActive;
};
export const hasStudentLoginCodeViewArchive = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.viewArchived;
};
export const hasStudentLoginCodeViewBlocked = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.viewBlocked;
};

export const hasStudentLoginCodeAdd = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.itemAdd;
};
export const hasStudentLoginCodeEdit = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.itemUpdate;
};

export const hasStudentLoginCodeDeleteImage = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.itemDeleteImage;
};
export const hasStudentLoginCodeFinaleDelete = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.itemFinaleDelete;
};

export const hasStudentLoginCodeChangeActivationType = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.changeActivationType;
};
export const hasStudentLoginCodeActive = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.itemActive;
};
export const hasStudentLoginCodeArchive = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.itemArchived;
};
export const hasStudentLoginCodeBlocked = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.itemBlocked;
};
export const hasStudentLoginCodeResetCode = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.resetUserloginCode;
};
export const hasStudentLoginCodeResetDevice = () => {
  return store.getters.usersPrivilegeData.StudentLoginCode.resetUserDeviceCode;
};
//#endregion Student

//#region Parent
export const hasParentLoginCode = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.view;
};
export const hasParentLoginCodeViewActive = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.viewActive;
};
export const hasParentLoginCodeViewArchive = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.viewArchived;
};
export const hasParentLoginCodeViewBlocked = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.viewBlocked;
};

export const hasParentLoginCodeAdd = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.itemAdd;
};
export const hasParentLoginCodeEdit = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.itemUpdate;
};

export const hasParentLoginCodeDeleteImage = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.itemDeleteImage;
};
export const hasParentLoginCodeFinaleDelete = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.itemFinaleDelete;
};

export const hasParentLoginCodeChangeActivationType = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.changeActivationType;
};
export const hasParentLoginCodeActive = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.itemActive;
};
export const hasParentLoginCodeArchive = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.itemArchived;
};
export const hasParentLoginCodeBlocked = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.itemBlocked;
};
export const hasParentLoginCodeResetCode = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.resetUserloginCode;
};
export const hasParentLoginCodeResetDevice = () => {
  return store.getters.usersPrivilegeData.ParentLoginCode.resetUserDeviceCode;
};
//#endregion Parent

//#region Employee
export const hasEmployeeLoginCode = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.view;
};
export const hasEmployeeLoginCodeViewActive = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.viewActive;
};
export const hasEmployeeLoginCodeViewArchive = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.viewArchived;
};
export const hasEmployeeLoginCodeViewBlocked = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.viewBlocked;
};

export const hasEmployeeLoginCodeAdd = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.itemAdd;
};
export const hasEmployeeLoginCodeEdit = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.itemUpdate;
};

export const hasEmployeeLoginCodeDeleteImage = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.itemDeleteImage;
};
export const hasEmployeeLoginCodeFinaleDelete = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.itemFinaleDelete;
};

export const hasEmployeeLoginCodeChangeActivationType = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode
    .changeActivationType;
};
export const hasEmployeeLoginCodeActive = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.itemActive;
};
export const hasEmployeeLoginCodeArchive = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.itemArchived;
};
export const hasEmployeeLoginCodeBlocked = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.itemBlocked;
};
export const hasEmployeeLoginCodeResetCode = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.resetUserloginCode;
};
export const hasEmployeeLoginCodeResetDevice = () => {
  return store.getters.usersPrivilegeData.EmployeeLoginCode.resetUserDeviceCode;
};
//#endregion Employee

//#region MasterAdmin
export const hasMasterAdminLoginCode = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode.view;
};
export const hasMasterAdminLoginCodeViewActive = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode.viewActive;
};
export const hasMasterAdminLoginCodeViewArchive = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode.viewArchived;
};
export const hasMasterAdminLoginCodeViewBlocked = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode.viewBlocked;
};

export const hasMasterAdminLoginCodeAdd = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode.itemAdd;
};
export const hasMasterAdminLoginCodeEdit = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode.itemUpdate;
};

export const hasMasterAdminLoginCodeDeleteImage = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode.itemDeleteImage;
};
export const hasMasterAdminLoginCodeFinaleDelete = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode.itemFinaleDelete;
};

export const hasMasterAdminLoginCodeChangeActivationType = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode
    .changeActivationType;
};
export const hasMasterAdminLoginCodeActive = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode.itemActive;
};
export const hasMasterAdminLoginCodeArchive = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode.itemArchived;
};
export const hasMasterAdminLoginCodeBlocked = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode.itemBlocked;
};
export const hasMasterAdminLoginCodeResetCode = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode
    .resetUserloginCode;
};
export const hasMasterAdminLoginCodeResetDevice = () => {
  return store.getters.usersPrivilegeData.MasterAdminLoginCode
    .resetUserDeviceCode;
};
//#endregion MasterAdmin

//#region AdminsEgypt
export const hasAdminsEgyptLoginCode = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode.view;
};
export const hasAdminsEgyptLoginCodeViewActive = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode.viewActive;
};
export const hasAdminsEgyptLoginCodeViewArchive = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode.viewArchived;
};
export const hasAdminsEgyptLoginCodeViewBlocked = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode.viewBlocked;
};

export const hasAdminsEgyptLoginCodeAdd = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode.itemAdd;
};
export const hasAdminsEgyptLoginCodeEdit = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode.itemUpdate;
};

export const hasAdminsEgyptLoginCodeDeleteImage = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode.itemDeleteImage;
};
export const hasAdminsEgyptLoginCodeFinaleDelete = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode.itemFinaleDelete;
};

export const hasAdminsEgyptLoginCodeChangeActivationType = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode
    .changeActivationType;
};
export const hasAdminsEgyptLoginCodeActive = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode.itemActive;
};
export const hasAdminsEgyptLoginCodeArchive = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode.itemArchived;
};
export const hasAdminsEgyptLoginCodeBlocked = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode.itemBlocked;
};
export const hasAdminsEgyptLoginCodeResetCode = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode
    .resetUserloginCode;
};
export const hasAdminsEgyptLoginCodeResetDevice = () => {
  return store.getters.usersPrivilegeData.AdminsEgyptLoginCode
    .resetUserDeviceCode;
};
//#endregion AdminsEgypt

//#endregion UserLoginCodes

//#endregion UserSettings

//#endregion Settings

//#region Notifications
export const hasNotification = () => {
  try {
    return store.getters.usersPrivilegeData.Notification.notificationView;
  } catch (error) {
    return false;
  }
};
export const hasNotificationRead = () => {
  try {
    return store.getters.usersPrivilegeData.Notification.notificationRead;
  } catch (error) {
    return false;
  }
};
export const hasNotificationOpen = () => {
  try {
    return store.getters.usersPrivilegeData.Notification.notificationOpen;
  } catch (error) {
    return false;
  }
};

//#endregion Notifications

//#region UserOrders
export const hasUserOrderReport = () => {
  try {
    return store.getters.usersPrivilegeData.UserOrder.viewReport;
  } catch (error) {
    return false;
  }
};
//#endregion UserOrders

//#region Reports
export const hasReports = () => {
  return (
    hasStudentsIdReport() ||
    hasStudentsWalletReport() ||
    hasUserWalletTransactionReport() ||
    hasUserOrderReport()
  );
};

//#endregion Reports
